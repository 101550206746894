import en from './locales/en.json';
import ja from './locales/ja.json';

export default {
  legacy: false,
  locale: 'ja',
  messages: {
    en: en,
    ja: ja,
  },
};
